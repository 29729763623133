





























































import { Component, Vue, Watch } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Post = {
  id: number;
};

type PostMeta = {
  currentMode: number;
  currentPage: number;
};

@Component({
  components: {
    Fab: () => import("@/app/modules/whiteboard/components/Fab.vue"),
    Post: () => import("@/app/modules/whiteboard/components/PostComponent.vue")
  }
})
export default class PostsPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView("Posts (Whiteboard)");
  }

  localLazyLoaded: boolean[] = [];
  perPage = 10;

  @Watch("localLazyLoaded")
  onLazyLoadedChanged() {
    this.lazyLoaded = [...this.localLazyLoaded];

    const trues = this.localLazyLoaded.filter(value => value);

    if (!this.isApiFetching && this.localLazyLoaded.length && trues.length % this.perPage == 0) {
      this.fetchPosts(this.currentPage++);
    }
  }

  get isApiFetching() {
    return this.$store.getters["api/isApiFetching"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get postsData(): Post[] {
    return this.$store.getters["posts/data"];
  }

  get lazyLoaded(): boolean[] {
    return this.$store.getters["posts/lazyLoaded"];
  }

  set lazyLoaded(data: boolean[]) {
    this.$store.commit("posts/setLazyLoaded", [...data]);
  }

  get currentMode(): number {
    return this.$store.getters["posts/currentMode"];
  }

  set currentMode(mode: number) {
    this.$store.commit("posts/setCurrentMode", mode);
  }

  get currentPage(): number {
    return this.$store.getters["posts/currentPage"];
  }

  set currentPage(page: number) {
    this.$store.commit("posts/setCurrentPage", page);
  }

  get lastPage(): number {
    return this.$store.getters["posts/lastPage"];
  }

  created() {
    if (!this.postsData) this.fetchPosts(this.currentPage);
    else this.localLazyLoaded = [...this.lazyLoaded];
  }

  fetchPosts(currentPage: number, modeChanged = false) {
    if (modeChanged) {
      this.currentPage = 1;
      this.localLazyLoaded = [];

      this.$store.commit("posts/setData", []);
      this.$store.commit("posts/setLastPage", null);
    }

    if (this.lastPage && this.currentPage > this.lastPage) return;

    const meta: PostMeta = {
      currentMode: this.currentMode,
      currentPage: this.currentPage
    };

    this.$store.dispatch("posts/fetchAll", meta).then(({ data }) => {
      this.$store.commit("posts/setLastPage", data.meta.last_page);

      if (this.perPage != data.meta.per_page) this.perPage = data.meta.per_page;
      if (!this.postsData) this.$store.commit("posts/setData", [...data.data]);
      else this.$store.commit("posts/setData", [...this.postsData, ...data.data]);
    });
  }
}
